<template>
	<article>
		<hero-image
			:alt="hero.alt"
			:heading="hero.heading"
			:img-small="hero.imgSmall"
			:img-large="hero.imgLarge"
		/>
		<v-container class="py-0">
			<v-row no-gutters align="center">
				<v-col :order="$vuetify.breakpoint.xsOnly ? 'last' : null" cols="12" sm="7">
					<breadcrumbs :crumbs="crumbs" />
				</v-col>
				<v-col cols="12" sm="5">
					<social />
				</v-col>
			</v-row>
		</v-container>
		<v-container class="content" tag="section">
			<v-row>
				<v-col cols="12">
					<p
						class="blockquote"
					>
						Starting school is an important step in a young child's life and many parents worry about their child's readiness to start school. Children are often held back from school entry when they are born close to the entry cutoff date, or when there is a history of developmental delay.
					</p>
					<p>A school readiness assessment can be undertaken to determine if a child is intellectually and emotionally ready to start school. This assessment will evaluate your child’s strengths and suggestions can be given to ensure that your child has the best transition to primary school. For children with intellectual or cognitive delays, this assessment will also assist in identifying the most suitable educational setting to support a child's learning needs.</p>
				</v-col>
				<v-col cols="12">
					<cta :color="$vuetify.theme.themes.light.school" icon="$vuetify.icons.school" />
				</v-col>
			</v-row>
		</v-container>
	</article>
</template>

<script>
import { _MxAppViewClass, _MxMeta } from '@/mixins/global';
import Breadcrumbs from '@/components/breadcrumbs/Breadcrumbs';
import Cta from '@/components/cta/Cta';
import HeroImage from '@/components/image/HeroImage';
import Social from '@/components/social/Social';
export default {
	name: 'SchoolReadiness',
	components: {
		Breadcrumbs,
		Cta,
		HeroImage,
		Social
	},
	mixins: [_MxAppViewClass, _MxMeta],
	data() {
		return {
			crumbs: [
				{
					text: 'home',
					disabled: false,
					to: '/'
				},
				{
					text: 'services',
					disabled: false,
					to: '/services'
				},
				{
					text: this.$route.name,
					disabled: true
				}
			],
			hero: {
				alt: this.$route.name,
				heading: this.$route.name,
				imgSmall: 'hero-12-small',
				imgLarge: 'hero-12-large'
			},
			meta: {
				description: 'description',
				title: 'School Readiness'
			}
		};
	}
};
</script>
